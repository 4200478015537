import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs';

import { ModalFieldComponent } from '../../../forms/modal-field.component';
import { MfaService } from '../../../reducers/mfa/mfa.service';
import { ContentStateComponent } from '../../content-state/content-state.component';

@Component({
  selector: 'configure-authenticator',
  templateUrl: './configure-authenticator.component.html',
  standalone: true,
  imports: [TranslateModule, ContentStateComponent, ReactiveFormsModule, ModalFieldComponent],
})
export class ConfigureAuthenticatorComponent implements OnInit {
  @HostBinding('class')
  public class = 'flex flex-col gap-3';

  public qr = {
    secret: '',
    qr_url: undefined,
  };

  public loading = true;

  @Input()
  public form: UntypedFormGroup;
  @Input()
  public provider: string;

  public constructor(
    private service: MfaService,
    private domSanitizer: DomSanitizer,
  ) {}

  public ngOnInit() {
    const data = {
      provider: this.provider,
    };

    void this.service
      .setup(data)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe((response) => {
        this.qr = {
          secret: response.secret,
          qr_url: this.domSanitizer.bypassSecurityTrustUrl(response.qr_url),
        };
      });
  }
}
